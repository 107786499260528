import React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import { Linkedin } from "styled-icons/bootstrap";
import {
  AboutUsSection,
  Hero,
  HiringSection,
  InversorsSection,
  TeamSection,
} from "../styles/pages/about-us";
import OMG from "../images/home/omg.png";
import underlinedHero from "../images/home/underlined-hero.png";
import aboutUsTeam from "../images/home/about-us-team.png";
import regularVacancy from "../images/home/REGULAR_VACANCY.png";
import kima from "../images/home/kima.png";
import gain from "../images/home/gain.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation("us");

  const team = [
    {
      name: "Juan Carlos Martínez",
      position: "CTO",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FCARLITOS.png?alt=media&token=1ad3a8d4-19da-491c-8eaf-80b633383d99",
      ulrLinkendIn:
        "https://www.linkedin.com/in/juan-carlos-martinez-elizondo/",
    },
    {
      name: "Pol Morral",
      position: "CEO",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FPOL.png?alt=media&token=992cd56c-80e2-4724-a05e-edc84b81cb2b",
      ulrLinkendIn: "https://www.linkedin.com/in/pol-morral-dauvergne/",
    },
    {
      name: "Pedro González Hernández",
      position: "Sr. Developer",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FPEDRO.png?alt=media&token=977be602-e5b5-468f-9387-15c29de64967",
      ulrLinkendIn: "https://www.linkedin.com/in/pedro-gza-hdz/",
    },
    {
      name: "José Miguel Carrera ",
      position: "Solution Architect",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FPACHECO.png?alt=media&token=c4bd6b40-9814-4a0a-893c-61baa0f48e5a",
      ulrLinkendIn: "https://www.linkedin.com/in/jose-miguel-carrera-pacheco/",
    },
    {
      name: "José Eduardo Romero",
      position: "Sr. React Developer",
      picture: "",
      ulrLinkendIn: "https://www.linkedin.com/in/je-romero-dev/",
    },
    {
      name: "Juan Martínez",
      position: "Sr. Developer",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FJUAN.png?alt=media&token=008e553f-b8c5-460e-8e6d-af60173a1230",
      ulrLinkendIn: "https://www.linkedin.com/in/juanmosorio/",
    },
    {
      name: "Dania Ruiz",
      position: "Product Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FDANIA.png?alt=media&token=668a1b22-971e-4624-9c2d-00877efe091e",
      ulrLinkendIn: "https://www.linkedin.com/in/dania-ruiz-villa/",
    },
    {
      name: "Matías Romero",
      position: "QA Tester Trainee",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FMATI%CC%81AS%20(1).png?alt=media&token=96d8f650-853a-49b4-8b57-d2de18e1c767",
      ulrLinkendIn: "https://www.linkedin.com/in/matias-romero-qa-testing/",
    },
    {
      name: "Karina Villamar",
      position: "Marketing Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FKARILAU.png?alt=media&token=7e9a084b-0c0f-4678-995d-a924dd8e4ab0",
      ulrLinkendIn:
        "https://www.linkedin.com/in/karina-a-villamar-lau-513673165/",
    },
    {
      name: "Elena Ibarrondo",
      position: "Graphic Designer",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FELENA.png?alt=media&token=b5d129da-c254-44ac-a46e-b9615eb061eb",
      ulrLinkendIn:
        "https://www.linkedin.com/in/elena-ibarrondo-pe%C3%B1a-05a452167/",
    },
    {
      name: "Erick Ramírez",
      position: "LaPieza Academy Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FERICK.png?alt=media&token=d538845c-0a60-4702-bc1e-20e16f8b85bd",
      ulrLinkendIn: "https://www.linkedin.com/in/erickramirezcolunga/",
    },
    {
      name: "Alejandra Isosorbe",
      position: "Career Coach",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FALE.png?alt=media&token=fe7507be-f45a-4932-ae3b-f6edb1932b27",
      ulrLinkendIn: "https://www.linkedin.com/in/alejandra-isosorbe/",
    },
    {
      name: "Tulia Valdez",
      position: "Career Coach",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FTULIA.png?alt=media&token=a641b026-6a30-42c8-bae4-efb68c4b03b2",
      ulrLinkendIn: "https://www.linkedin.com/in/tuliavaldez/",
    },
    {
      name: "Paz Zarza",
      position: "Academy Experience Analyst",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FPAZ.png?alt=media&token=f3650972-4a1e-429c-a3e2-c2f06e2f19a3",
      ulrLinkendIn: "https://www.linkedin.com/in/paz-zarza/",
    },
    {
      name: "Mariangela Marín",
      position: "Academy Customer Care",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FMariangela.png?alt=media&token=78dbe374-1241-42d3-aa50-1814b4603441",
      ulrLinkendIn: "https://www.linkedin.com/in/mariangela-marin/",
    },
    {
      name: "Alexa Villegas",
      position: "Head of Headhunting Operations",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FALEXA.png?alt=media&token=4fc74e00-819b-4034-9fea-85509ab4e674",
      ulrLinkendIn: "https://www.linkedin.com/in/alexa-villegas-236506199/",
    },
    {
      name: "Carolina López",
      position: "Headhunting Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FCARO.png?alt=media&token=06e933d8-7dc1-4a08-beff-ed61b0aaf149",
      ulrLinkendIn:
        "https://www.linkedin.com/in/carolina-lopez-solis-76b5a2144/",
    },
    {
      name: "Valentina Fernández",
      position: "Headhunting Intern",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FValentina%20Ferna%CC%81ndez.png?alt=media&token=a6482f14-a507-4e3d-96be-fd268968bd7f",
      ulrLinkendIn:
        "https://www.linkedin.com/in/valentina-fern%C3%A1ndez-b84962225/",
    },
    {
      name: "Cintia Boffa",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FCIN.png?alt=media&token=2edf5128-04b0-4aeb-8232-e3e9f75e21b0",
      ulrLinkendIn: "https://www.linkedin.com/in/cintia-boffa/",
    },
    {
      name: "Brisa Chumba",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FBRISA.png?alt=media&token=494ff870-4851-4df9-afdc-d151a7093432",
      ulrLinkendIn: "https://www.linkedin.com/in/brisa-chumba/",
    },
    {
      name: "Geraldina Untermann",
      position: "Headhunting Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FGERAL%20(2).png?alt=media&token=ba1f56bf-c801-4fe7-a131-914a0b174c24",
      ulrLinkendIn:
        "https://www.linkedin.com/in/geraldina-untermann-1a44131a1/",
    },
    {
      name: "Jair García",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FJAIR.png?alt=media&token=e43d0af4-b406-46d5-876e-dc3e238222fd",
      ulrLinkendIn:
        "https://www.linkedin.com/in/jair-garcia-sanchez-head-hunter/",
    },
    {
      name: "María Estrada",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FMARY.png?alt=media&token=dfd4b985-5a20-4638-9d47-355133e12a27",
      ulrLinkendIn:
        "https://www.linkedin.com/in/mar%C3%ADa-elena-estrada-duarte-7753b6210/",
    },
    {
      name: "Alexa Kubli",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FALEXA%20K.png?alt=media&token=1c97f677-603e-41b2-98d8-d6a763d7ab6e",
      ulrLinkendIn:
        "https://www.linkedin.com/in/alexa-kubli-gollner-11424815b/",
    },
    {
      name: "Marijosé Murillo",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FMarijose.png?alt=media&token=0d9a7bb3-5535-4654-88c8-9d168172968d",
      ulrLinkendIn: "https://www.linkedin.com/in/marijose-murillo-24a4791ba/",
    },
    {
      name: "Camila Cordobés",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FCAMI.png?alt=media&token=f72218b6-28b1-4839-8ae0-8718030c5518",
      ulrLinkendIn: "https://www.linkedin.com/in/camila-cordob%C3%A9s/",
    },
    {
      name: "Arantza Gutiérrez",
      position: "Headhunting Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FARA.png?alt=media&token=3c896539-6aa7-4f11-8298-4f00d059fec2",
      ulrLinkendIn:
        "https://www.linkedin.com/in/arantza-guti%C3%A9rrez-23004a171/",
    },
    {
      name: "Daniela Bongianino",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FDANI.png?alt=media&token=3552a981-5fc2-4956-948d-112696b6dbc8",
      ulrLinkendIn:
        "https://www.linkedin.com/in/mar%C3%ADa-daniela-bongianino1998/",
    },
    {
      name: "Giuliana Gironzini",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FGIU.png?alt=media&token=c4d2d6d7-3b14-4728-89f0-f2c8c1fa7071",
      ulrLinkendIn: "https://www.linkedin.com/in/giulianagironzini/",
    },
    {
      name: "Arlette Lizárraga",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FARLETTE.png?alt=media&token=254a898e-3895-4b52-a0d4-8dd3c228011d",
      ulrLinkendIn: "https://www.linkedin.com/in/arlettelizarraga/",
    },
    {
      name: "Valentina Mora",
      position: "Headhunting Support & Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FValen%20M.png?alt=media&token=1b40e833-c25e-46b2-8f15-21db1eab53dc",
      ulrLinkendIn: "https://www.linkedin.com/in/valentinamorar/",
    },
    {
      name: "Andrea Carmona",
      position: "Headhunting Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FANDY.png?alt=media&token=189d2443-a205-48a6-b889-3e892ce8a5aa",
      ulrLinkendIn:
        "https://www.linkedin.com/in/andrea-carmona-ramos-4b9b16149/",
    },
    {
      name: "Melanie Jascovich",
      position: "Headhunter",
      picture: "",
      ulrLinkendIn:
        "https://www.linkedin.com/in/melanie-aylen-jascovich-7557b2140/",
    },
    {
      name: "Fernanda Floreste",
      position: "Headhunter",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FFER.png?alt=media&token=a8aee8d7-0007-4f01-9c60-ee403a484cfd",
      ulrLinkendIn: "https://www.linkedin.com/in/fernandafloreste/",
    },
    {
      name: "Alexia Montoy",
      position: "Headhunting Manager",
      picture: "",
      ulrLinkendIn: "https://www.linkedin.com/in/alexia-montoy-2489a5221/",
    },
    {
      name: "Jordy Suárez",
      position: "Headhunting Intern",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FJORDY.png?alt=media&token=f20b24af-7640-4b3f-a638-7d6a5460b61d",
      ulrLinkendIn: "https://www.linkedin.com/in/jordy-suarez29/",
    },
    {
      name: "Edgar Carapia",
      position: "Headhunting Intern",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FEDGAR.png?alt=media&token=4e363f94-4f15-4586-b241-93f58785464b",
      ulrLinkendIn: "https://www.linkedin.com/in/marco-garc%C3%ADa/",
    },
    {
      name: "Sol Petit",
      position: "Headhunting Intern",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FSOL.png?alt=media&token=8f649294-0bb4-4cc9-894d-286b6d25f999",
      ulrLinkendIn: "https://www.linkedin.com/in/sol-petit98/",
    },
    {
      name: "Isabela Reyes",
      position: "Headhunting Intern",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FISA.png?alt=media&token=8044d6fd-8998-4400-9c72-b1bd64c1c6f7",
      ulrLinkendIn: "https://www.linkedin.com/in/isabela-reyes-gonzales/",
    },
    {
      name: "Guadalupe Padilla",
      position: "Headhunting Intern",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FGUADALUPE.png?alt=media&token=4fc6f0ef-7162-4d1e-a539-f1f9cb4328fb",
      ulrLinkendIn: "https://www.linkedin.com/in/guadalupe-padilla-51396a22b/",
    },
    {
      name: "Ana Paola Solis",
      position: "Headhunter",
      picture: "",
      ulrLinkendIn: "https://www.linkedin.com/in/anapaolasolismacias/",
    },
    {
      name: "Amparo Arredondo",
      position: "People Ops Coordinator",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FAMPARO%20(2).png?alt=media&token=10801b5f-fcc9-4870-9751-beac8b9b3821",
      ulrLinkendIn: "https://www.linkedin.com/in/amparoa/",
    },
    {
      name: "Desiree Rodríguez",
      position: "Customer Success & Financial Analyst",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FDES.png?alt=media&token=182a03fc-0f4e-439d-87f4-a8dfa4184493",
      ulrLinkendIn: "https://www.linkedin.com/in/ydesireers/",
    },
    {
      name: "Renata Manzanares",
      position: "CS & Sales Manager",
      picture:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2Fteam%2FREN.png?alt=media&token=02747531-8b60-432a-8244-5e7ad82d4872",
      ulrLinkendIn:
        "https://www.linkedin.com/in/renata-manzanares-balderas-158182139/",
    },
  ];

  return (
    <>
      <Seo
        title="Servicio de reclutamiento Encontramos al mejor talento para tu empresa."
        description="Servicio de reclutamiento especializado en TI y otras áreas ¿Buscas programadores, profesionales de marketing, finanzas, UX/UI?"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Layout>
        <Hero>
          <div className="main-landing-container-row">
            <article>
              <h1>{t("heroTitle")}</h1>
              <h2>
                {t("heroSubTitle")}
                <span>
                  <img src={underlinedHero} alt="Nuestro equipo" />
                </span>
              </h2>
              <p>{t("heroSubDescription")}</p>
            </article>
            <figure>
              <img src={OMG} alt="Figura de LaPieza" />
            </figure>
          </div>
        </Hero>
        <AboutUsSection>
          <div className="about-section main-landing-container-column">
            <article>
              <h2>{t("aboutUsTitle")}</h2>
              <p>{t("aboutUsDescription")}</p>
            </article>
            <div className="about-us-list">
              <ul>
                <li>
                  <div className="dot-container">
                    <span className="dot" />
                    <span className="dot-line" />
                  </div>
                  <article>
                    <h5>
                      <span>2018</span> {t("aboutUsItemTitle1")}
                    </h5>
                    <p>{t("aboutUsItemDescription1")}</p>
                  </article>
                </li>
                <li>
                  <div className="dot-container">
                    <span className="dot" />
                    <span className="dot-line" />
                  </div>
                  <article>
                    <h5>
                      <span>2019</span> {t("aboutUsItemTitle2")}
                    </h5>
                    <p>{t("aboutUsItemDescription2")}</p>
                  </article>
                </li>
                <li>
                  <div className="dot-container">
                    <span className="dot" />
                    <span className="dot-line" />
                  </div>
                  <article>
                    <h5>
                      <span>2020</span> {t("aboutUsItemTitle3")}
                    </h5>
                    <p>{t("aboutUsItemDescription3")}</p>
                  </article>
                </li>
                <li>
                  <div className="dot-container">
                    <span className="dot" />
                  </div>
                  <article>
                    <h5>
                      <span>2021</span> {t("aboutUsItemTitle4")}
                    </h5>
                    <p>{t("aboutUsItemDescription4")}</p>
                  </article>
                </li>
                <li>
                  <div className="dot-container">
                    <span className="dot" />
                  </div>
                  <article>
                    <h5>
                      <span>2022</span> {t("aboutUsItemTitle5")}
                    </h5>
                    <p>{t("aboutUsItemDescription5")}</p>
                  </article>
                </li>
              </ul>
              <figure>
                <img src={aboutUsTeam} alt="Equipo de LaPieza 2019" />
              </figure>
            </div>
          </div>
        </AboutUsSection>
        <HiringSection>
          <div className="hiring-section main-landing-container-row">
            <figure>
              <img src={regularVacancy} alt="Figura de LaPieza" />
            </figure>
            <article>
              <h2>{t("hiringTitle")}</h2>
              <p>{t("hiringDescription")}</p>
              <a
                href="https://lapieza.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("hiringButton")}
              </a>
            </article>
          </div>
        </HiringSection>
        <TeamSection>
          <div className="main-landing-container-column">
            <h2>{t("teamTitle")}</h2>
            <h3>{t("teamSubtitle")}</h3>
            <div className="team-list">
              {team.map((item, idx) => (
                <>
                  {item.picture && (
                    <article key={`member-item-${idx}`}>
                      <figure>
                        <img src={item.picture} alt="Foto de Pol en LaPieza" />
                      </figure>
                      <div>
                        <a
                          href={item.ulrLinkendIn}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h5>
                            <Linkedin
                              className="icon-linkedin"
                              size={20}
                              color="#0a66c2"
                            />
                            {item.name}
                          </h5>
                        </a>
                        <p>{item.position}</p>
                      </div>
                    </article>
                  )}
                </>
              ))}
            </div>
          </div>
        </TeamSection>
        <InversorsSection>
          <div className="inverstor-section main-landing-container-row">
            <article>
              <h2>{t("inversorTitle")}</h2>
              <Link to="/contact">{t("inversorButton")}</Link>
            </article>
            <div>
              <figure>
                <img src={kima} alt="Logo de Kima Ventures" />
              </figure>
              <figure>
                <img src={gain} alt="Logo de GAIN" />
              </figure>
            </div>
          </div>
        </InversorsSection>
      </Layout>
    </>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
